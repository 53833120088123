import React, { useState, useContext } from "react";
// import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { UserContext } from "../context/userContext";
import { IUserContextType } from "../types";
import subscribeService from "../utils/subscribe.service";
import { toast } from "react-toastify";
import { generateCPUrl, UrlProps } from "../utils/urls";
import { generateUUID } from "../utils/uuidFunc";

interface TUserDetailsProps {
  setLoading: any;
  setConfirmation: any;
  setInputMSISDN: any;
  setValidateSubscription: any;
}

export const UserDetails = (props: TUserDetailsProps) => {
  const { user, setUser } = useContext(UserContext);
  console.log(user);

  const [value, setValue] = useState<any>();

  const handleChange = (e: any) => {
    setValue(e);
  };

  // upon clicking enter follow the follwing steps
  // 1. check whether the msisdn is present
  // 2. query for subscribed products
  // 3. if length < 0 redirect to DOI
  // 4. if length > 0 and product is same as the content spec then => submit OTP and get the content url

  const handleDOICheck = () => {
    props.setLoading(true);
    const number = value.substring(1);

    // check if consume or subscribe
    if (user?.subsciptionStatus === "consume") {
      // set Loading status
      props.setLoading(true);

      // format the number to a valid number
      let newNumber = value.substring(1);
      // dispatch action to send msisdn to backend
      setUser({ ...user, msisdn: newNumber });

      // hit the subscribe verify endpoint
      subscribeService
        .verifySubscription({
          msisdn: newNumber,
        })
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            props.setInputMSISDN(false);
            props.setConfirmation(true);
            toast.success("OTP sent successfully", {
              position: toast.POSITION.TOP_LEFT,
            });
          } else {
            // toast error notification
            toast.error("An error occured. Please Try again", {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          // set loading to false
          props.setLoading(false);
          console.log(err);

          // toast error notification
          toast.error("An error occured. Please Try again", {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    } else {
      // check if the number exists
      if (number) {
        //query for subsribed products
        subscribeService
          .fetchUserSubscriptions({ msisdn: number })
          .then((res: any) => {
            props.setLoading(false);
            const {
              data: { products },
            } = res;

            // check id the res.data.products length is greater than 0
            if (products.length > 0) {
              //  check for the specific product that the user wants o subscribe to
              const subPhrase = user.contentSpec.split("_");
              const newWord = `${subPhrase[0]}_${subPhrase[1]}_${subPhrase[2]}`;

              const matchingProduct = products.find((product: any) => {
                return product.subscriptionId.includes(newWord);
              });

              if (matchingProduct) {
                // add the msisdn to store
                setUser({ ...user, msisdn: number });

                // hit the otp endpoint
                subscribeService
                  .verifySubscription({
                    msisdn: number,
                  })
                  .then((res: any) => {
                    props.setLoading(false);
                    if (res.status === 200) {
                      props.setInputMSISDN(false);
                      props.setConfirmation(true);
                      toast.success("OTP sent successfully", {
                        position: toast.POSITION.TOP_LEFT,
                      });
                    } else {
                      // toast error notification
                      toast.error("An error occured. Please Try again", {
                        position: toast.POSITION.TOP_LEFT,
                      });
                    }
                  })
                  .catch((err) => {
                    // set loading to false
                    props.setLoading(false);
                    // toast error notification
                    toast.error("An error occured. Please Try again", {
                      position: toast.POSITION.TOP_LEFT,
                    });
                  });
                // props.setInputMSISDN(false)
                // props.setConfirmation(true)
              } else {
                // redirect to DOI
                const CP_PAYLOAD: UrlProps = {
                  endpoint: "http://api.vm-vas-sm.mtn.zm/",
                  CpId: "IMS DIGITAL",
                  CpPwd: "IMSDIGITAL@123",
                  cpName: "IMS DIGITAL",
                  planId: user?.contentSpec,
                  opId: 1,
                  transactionId: generateUUID(),
                };

                const url = generateCPUrl(CP_PAYLOAD);
                if (url) {
                  window.open(url, "_self");
                }
              }
            } else {
              // redirect to DOI
              const CP_PAYLOAD: UrlProps = {
                endpoint: "http://api.vm-vas-sm.mtn.zm/",
                CpId: "IMS DIGITAL",
                CpPwd: "IMSDIGITAL@123",
                cpName: "IMS DIGITAL",
                planId: user?.contentSpec,
                opId: 1,
                transactionId: generateUUID(),
              };

              const url = generateCPUrl(CP_PAYLOAD);

              if (url) {
                window.open(url, "_self");
              }
            }
          })
          .catch((err) => {
            console.log(err)
            props.setLoading(false);
            toast.error("An error occured!", {
              position: toast.POSITION.TOP_LEFT,
            });
          });
      } else {
        toast.error("Invalid phone number format", {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    }
  };

  // const handleSubmit = () => {
  //   // set Loading status
  //   props.setLoading(true)

  //   // format the number to a valid number
  //   let newNumber = value.substring(1)
  //   // dispatch action to send msisdn to backend
  //   setUser({...user, msisdn: newNumber})

  //   // hit the subscribe verify endpoint
  //   subscribeService.verifySubscription({
  //     msisdn: newNumber
  //   }).then(res => {
  //     props.setLoading(false)
  //     if(res.status === 200) {
  //       props.setInputMSISDN(false)
  //       props.setConfirmation(true)
  //       toast.success("OTP sent successfully", {
  //         position: toast.POSITION.TOP_LEFT
  //       })
  //     } else {
  //       // toast error notification
  //       toast.error("An error occured. Please Try again", {
  //         position: toast.POSITION.TOP_LEFT
  //       })
  //     }

  //   }).catch(err => {
  //     // set loading to false
  //     props.setLoading(false)
  //     console.log(err);

  //     // toast error notification
  //     toast.error("An error occured. Please Try again", {
  //       position: toast.POSITION.TOP_LEFT
  //     })
  //   })
  // }

  return (
    <div className="p-4">
      <div className="space-y-4 flex flex-col items-center">
        <label></label>
        <label className="text-center text-sm">
          Enter your Mobile Number below{" "}
        </label>
        <PhoneInput
          international
          defaultCountry="ZM"
          countryCallingCodeEditable={false}
          placeholder="Enter phone number"
          value={value}
          onChange={handleChange}
          error={
            value
              ? isValidPhoneNumber(value)
                ? undefined
                : "Invalid phone number"
              : "Phone number required"
          }
          className="border-2 pl-2 rounded-lg"
        />

        <button
          type="button"
          onClick={handleDOICheck}
          disabled={value && !isPossiblePhoneNumber(value)}
          className={` ${
            value && isPossiblePhoneNumber(value)
              ? "focus:ring-4 focus:ring-blue-300 bg-blue-700 hover:bg-blue-800"
              : "cursor-not-allowed bg-gray-200"
          } w-[85%] ml-2 text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none`}
        >
          Enter
        </button>
      </div>
    </div>
  );
};
