import React, { MouseEventHandler, useEffect, useState } from "react";
import { Confirmation } from "../components/Confirmation";
import { Enjoy } from "../components/Enjoy";
import { Join } from "../components/Join";
import { Loader } from "../components/Loader";
import { Navbar } from "../components/Navbar";
import { UserDetails } from "../components/UserDetails";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Footer } from "../components/Footer";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";

// import images
import FootballOne from "../assets/highSportsWorld8.png";
import FootballTwo from "../assets/highSportsWorld-4.png";
import MTNLogo from "../assets/MTN_2022_Logo_Black_RGB.png";
import Celebrity from "../assets/celebrity_choice.png";
import Soccer from "../assets/soccer.jpg";

// import icons
import { FaPlay } from "react-icons/fa";
import { TContentProviders } from "../types";
import ValidateSubscription from "../components/ValidateSubscription";
import subscribeService from "../utils/subscribe.service";

const contentProviders: TContentProviders[] = [
  {
    id: 1,
    title: "SportsWorldTV",
    baseSubscription: "P_IMSDI_Sport",
    image: FootballTwo,
    category: "Sports",
    subscriptionSpecs: [
      {
        subscriptionId: "P_IMSDI_Sport_490",
        spec: "daily",
        paymentPlan: "K3.5 per day",
      },
      {
        subscriptionId: "P_IMSDI_Sport_491",
        spec: "weekly",
        paymentPlan: "K10.0 per week",
      },
      {
        subscriptionId: "P_IMSDI_Sport_492",
        spec: "monthly",
        paymentPlan: "K25.0 per month",
      },
    ],
  },
  {
    id: 2,
    title: "Celebrity Choice",
    baseSubscription: "P_IMSDI_CELEB",
    image: Celebrity,
    category: "Celebrity",
    subscriptionSpecs: [
      {
        subscriptionId: "P_IMSDI_CELEB_493",
        spec: "daily",
        paymentPlan: "K2.5 per day",
      },
      {
        subscriptionId: "P_IMSDI_CELEB_494",
        spec: "weekly",
        paymentPlan: "K10.0 per week",
      },
      {
        subscriptionId: "P_IMSDI_CELEB_495",
        spec: "monthly",
        paymentPlan: "K25.0 per month",
      },
    ],
  },
];

export const HomeView = () => {
  const [inputMSISDN, setInputMSISDN] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(true);
  const [validateSubscription, setValidateSubscription] =
    useState<boolean>(false);

  const [enjoyContent, setEnjoyContent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<number | undefined>();
  const params = useParams();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleScroll = (item: any) => {
    const element = document.getElementById(item);
    element?.scrollIntoView({ behavior: "smooth" });
  };


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    
    if (queryParams.get("tid")) {
      // get the values and push them to the local storage
      localStorage.setItem("user-tracking-details", JSON.stringify({
        banner: queryParams.get("banner"),
        marketingComppaignTid: queryParams.get("tid"),
        marketingPartner: queryParams.get("mpname")
      }))
    }

    if (queryParams.get("MSISDN")) {
      localStorage.setItem("DOI-details", JSON.stringify({
        doiResultDesc: queryParams.get("Result"),
        msisdn: queryParams.get("MSISDN"),
        doiResultReason: queryParams.get("Reason"),
        productId: queryParams.get("productId"),
        doiTransactionId: queryParams.get("transID"),
        tpcgid: queryParams.get("TPCGID"),
      }))



      subscribeService.subscriptionLogging({
        ...JSON.parse(localStorage.getItem("user-tracking-details") || '{}'),
        ...JSON.parse(localStorage.getItem("DOI-details") || '{}')
      });
    }
 }, []);

  // console.log({productName,category})

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1 my-16">
        {/* hero section */}
        <Slider {...settings} className="w-[100%] mx-auto">
          <div className="relative container--image">
            <img className="container_img_two" src={FootballOne} alt="text" />

            <div className="absolute top-[30%] md:top-[30%] sm:top-[30%] left-[4%] sm:left-[6%] w-[100%] sm:w-[30%]  sm:w-1/3">
              <h1 className="text-lg sm:text-5xl text-white mb-4 sm:mb-6">
                SportsWorldTV
              </h1>

              <p className="text-xs sm:text-xl text-white mb-4">
                Providing sporting events a voice through Broadcast of
                Production Services
              </p>

              <button
                onClick={() => handleScroll("Sport-World")}
                type="button"
                className="py-2 px-3 text-xs text-white border border-white hover:bg-white hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg sm:text-sm sm:p-2.5 text-center inline-flex items-center mr-2 space-x-2"
              >
                <span>Subscribe to SportsWorldTV</span>

                <svg
                  aria-hidden="true"
                  className="w-3 h-3 sm:w-5 sm:h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>

                <span className="sr-only">Icon description</span>
              </button>
            </div>
          </div>

          <div className="relative container--image">
            <img className="container_img_two" src={Celebrity} alt="text" />

            <div className="absolute top-[30%] md:top-[30%] sm:top-[30%] left-[4%] sm:left-[6%] w-[100%] sm:w-[30%]  sm:w-1/3">
              <h1 className="text-lg sm:text-5xl text-white mb-4 sm:mb-6">
                Celebrity Choice
              </h1>

              <p className="text-xs sm:text-xl text-white mb-4">
                Providing events a voice through Broadcast of Production
                Services
              </p>

              <button
                onClick={() => handleScroll("Celebrity-Fantasy")}
                type="button"
                className="py-2 px-3 text-xs text-white border border-white hover:bg-white hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg sm:text-sm sm:p-2.5 text-center inline-flex items-center mr-2 space-x-2"
              >
                <span>Subscribe to Celebrity Choice</span>

                <svg
                  aria-hidden="true"
                  className="w-3 h-3 sm:w-5 sm:h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>

                <span className="sr-only">Icon description</span>
              </button>
            </div>
          </div>
        </Slider>

        {/* content sections */}
        <div className="sm:container sm:mx-auto my-8 px-4 md:px-4 sm:px-0">
          <div>
            <h3 className="font-bold mb-2">Sports & News</h3>
            <p className="text-lg">
              Catch all the latest sports news and highlights from around the
              world!
            </p>
          </div>

          {contentProviders &&
            contentProviders?.map((item: TContentProviders, index: number) => (
              <div
                id={item?.title}
                key={index}
                className="my-6 shadow-xl rounded-lg"
              >
                {content === item.id && loading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="flex flex-col sm:flex-row">
                      <div className="w-[100%] md:w-50-[50%] sm:w-[70%]">
                        <img
                          className={`${
                            item.title === "Sports-WorldTV"
                              ? "container_img"
                              : "container_img"
                          }`}
                          src={item.image}
                          alt="football One"
                        />
                      </div>
                      <div className="w-[100%] md:w-[50%] sm:w-[30%]">
                        {content === item.id && inputMSISDN ? (
                          <UserDetails
                            setLoading={setLoading}
                            setConfirmation={setConfirmation}
                            setInputMSISDN={setInputMSISDN}
                            setValidateSubscription={setValidateSubscription}
                          />
                        ) : content === item.id && confirmation ? (
                          <Confirmation
                            setLoading={setLoading}
                            setEnjoyContent={setEnjoyContent}
                            setConfirmation={setConfirmation}
                            setValidateSubscription={setValidateSubscription}
                            item={item}
                          />
                        ) : content === item.id && validateSubscription ? (
                          <ValidateSubscription
                            setLoading={setLoading}
                            setConfirmation={setConfirmation}
                            setInputMSISDN={setInputMSISDN}
                            setEnjoyContent={setEnjoyContent}
                            setValidateSubscription={setValidateSubscription}
                            item={item}
                          />
                        ) : content === item.id && enjoyContent ? (
                          <Enjoy item={item} />
                        ) : (
                          <Join
                            contentId={item.id}
                            inputMSISDN={inputMSISDN}
                            setInputMSISDN={setInputMSISDN}
                            setConfirmation={setConfirmation}
                            setContent={setContent}
                            setValidateSubscription={setValidateSubscription}
                            item={item}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex items-center gap-2 p-2">
                      <div className="inline-flex overflow-hidden relative justify-center items-center rounded-full">
                        <img
                          src={MTNLogo}
                          className="w-[4rem] h-[4rem]"
                          alt="Flowbite Logo"
                        />
                      </div>
                      <div className="font-bold">
                        <div>{item?.title}</div>

                        <div className="text-sm font-bold text-gray-500 dark:text-gray-400">
                          {item?.category}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
