import React, { useState } from "react";
import { Link } from "react-router-dom";
import MTNLogo from '../assets/MTN_2022_Logo_Black_RGB.png'

export const Navbar = () => {

  const [showMenu, setShowMenu] = useState<boolean>(false)


  const handleMenuClick = () => {
    setShowMenu(!showMenu)
  }

  return (
    <nav className="p-2 bg-[#FFCB05] fixed w-[100%] z-20 top-0 left-0">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" className="flex items-center">
          <img
            src={MTNLogo}
            className="h-14 w-16 mr-3 sm:w-20 sm:h-20"
            alt="Flowbite Logo"
          />
          {/* <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            WEB
          </span> */}
        </Link>
        <button
          onClick={handleMenuClick}
          data-collapse-toggle="navbar-solid-bg"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-solid-bg"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className={` ${showMenu ? "" : "hidden"}   w-full md:block md:w-auto`} id="navbar-solid-bg">
          <ul className="flex flex-col mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent">
          <li>
              <Link
                to="/"
                onClick={handleMenuClick}
                className="block py-2 pl-3 pr-4 text-[1rem] text-black rounded hover:bg-white md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/terms"
                onClick={handleMenuClick}
                className="block py-2 pl-3 pr-4 text-[1rem] text-black rounded hover:bg-white md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0"
              >
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
