
import { Navbar } from "../components/Navbar";
import React from "react";
import AccordionItem, { AccordionItemProps } from "../components/Accordion";
import CelebrityChoice from "../components/CelebrityChoice";
import SportsWorld from "../components/SportsWorld";

const TermsAndConditions = () => {

  const accordionItems: AccordionItemProps[] = [
    {
      title: "SportsWorldTV",
      content: <SportsWorld />
    },
    {
      title: "Celebrity Choice",
      content: <CelebrityChoice/>
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div className="pt-[3rem] pl-[0.5rem] sm:pt-[5rem] font-bold text-2xl sm:text-3xl my-[2rem]">
          Terms And Conditions
        </div>
        <div className="w-full mx-auto">
          {accordionItems.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
