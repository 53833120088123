import React, {useContext } from "react";
import { UserContext } from "../context/userContext";

interface TValidateSubscriptionProps {
  setLoading: any;
  setConfirmation: any;
  setInputMSISDN: any;
  setEnjoyContent: any;
  setValidateSubscription: any;
  item: any;
}
function ValidateSubscription(props: TValidateSubscriptionProps) {
  // Look into a way of storing the msisdn in a global state => incorporate Context

  // access the user details from this point
  const {user} = useContext(UserContext)

  const onSubmit = (e: any) => {
    e.preventDefault();
    props.setLoading(true)

    switch (e.target.value) {
      case "accept":
        console.log(e.target.value)

        console.log("Hit the subscription api")

        console.log("response was success");

        setTimeout(() => {
            props.setLoading(false)
            props.setConfirmation(false)
            props.setValidateSubscription(false)
            props.setEnjoyContent(true)
          }, 5000)


        /* 
        Accept =>  Pass the accept string
        =>  Access the MSISDN and Product of subscription
        =>  Display Loading
        =>  Hit the subscription API and get response (Redirect URL)
        =>  pass the redirect URL request to the Enjoy Component 
        =>  Redirect the user to the Enjoy component
        */
        break;
      case "decline":
        console.log(e.target.value)

        console.log("Hit the subscription api")

        console.log("response of decline was success");

        // Set the Notification component to true for a few seconds the redirect back to the Join page

        setTimeout(() => {
            props.setLoading(false)
            props.setConfirmation(false)
            props.setValidateSubscription(false)
            props.setEnjoyContent(false)
          }, 5000)
        /* 
        Decline => Pass the decline string
            => Access the MSISDN and Product of subscription
            => Display Loading
            => Hit the subscription API and get decline response 
            => Return the user back to join screen
        */
        break;
      default:
        break;
    }
  };
  return (
    <div className="p-4 mt-4">
      <div>
        <h1 className="text-center font-semibold">
          You will be charged 100R for SportLockerContent
        </h1>
        <div className="flex justify-center gap-10 items-center my-4">
          <button
            type="button"
            value="accept"
            onClick={onSubmit}
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Accept
          </button>
          <button
            type="button"
            value="decline"
            onClick={onSubmit}
            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValidateSubscription;
