import React, { useContext } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { TEnjoyProps } from "../types";

import { ContentContext } from "../context/contentContext";
import { toast } from "react-toastify";

export const Enjoy = (props: TEnjoyProps) => {
  const { content } = useContext(ContentContext);

  const handleContentRedirect = () => {
    if(content.contentUrl) {
      window.open(content.contentUrl, '_blank')
    } else {
      toast.error("The subscription does not exist. Please join the service and try again!", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-[100%] h-[100%] gap-6 py-4">
      <p className="text-center font-medium text-sm">
        You have subscribed to {props.item.title}
      </p>
      <div onClick={handleContentRedirect}>
        <AiFillPlayCircle className="text-8xl" />
      </div>
      <p className="text-center font-medium text-sm">
        Click the button above to enjoy your videos
      </p>
    </div>
  );
};
