import React, { useState } from 'react';

export interface AccordionItemProps {
  title: string;
  content: any;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-200 rounded-md">
      <button
        className="flex items-center justify-between w-full px-4 py-3 text-lg font-medium text-left bg-white hover:bg-gray-100 focus:outline-none"
        onClick={toggleAccordion}
      >
        <span className='font-semibold'>{title}</span>
        <svg
          className={`w-5 h-5 transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>
      {isOpen && (
        <div className="px-4 py-3 bg-gray-100">
          <div className="text-gray-700">{content}</div>
        </div>
      )}
    </div>
  );
};

// const Accordion: React.FC = () => {
//   const accordionItems: AccordionItemProps[] = [
//     {
//       title: 'Accordion Item 1',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     },
//     {
//       title: 'Accordion Item 2',
//       content: 'Nulla volutpat aliquam velit, id lacinia neque cursus nec.',
//     },
//     {
//       title: 'Accordion Item 3',
//       content: 'In hac habitasse platea dictumst.',
//     },
//   ];

//   return (
//     <div className="max-w-md mx-auto">
//       {accordionItems.map((item, index) => (
//         <AccordionItem key={index} title={item.title} content={item.content} />
//       ))}
//     </div>
//   );
// };

export default AccordionItem;
