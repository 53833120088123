import httpClient from "./api";

// subscribe
interface ISubscriptionDetails {
  msisdn: string;
}
const verifySubscription = async (
  subscriptionDetails: ISubscriptionDetails
) => {
  // return {
  //     message: "12345"
  // }

  console.log(subscriptionDetails);
  const response = await httpClient.post(
    `/subscribe/verify/${subscriptionDetails.msisdn}`
  );
  console.log(response);
  return response;
};

const subscriptionLogging = async  (payload : any) => {
  const data = {payload}
  return httpClient.post('/v1/trace/logs/', payload)
}

// unsubscribe
interface IUnsubscriptionDetails {
  msisdn: string;
  type: string;
  productCode: string;
  subscriptionId: string;
  channel: string;
}
const unsubscribeByMsisdn = (subscriptionDetails: IUnsubscriptionDetails) => {
  const payload = {
    type: subscriptionDetails.type,
    productCode: subscriptionDetails.productCode,
    subscriptionId: subscriptionDetails.subscriptionId,
    channel: subscriptionDetails.channel,
  };

  return {
    products: [
      {
        id: 0,
        name: "string",
        subscriptionId: "string",
        providerId: "string",
        cost: 0,
        providerName: "string",
        category: "DAILY",
        trailPeriod: 0,
        period: 0,
      },
    ],
    status: {
      code: 0,
      status: "SUCCESS",
      message: "string",
      description: "string",
    },
  };

  // return httpClient.post(`/subscribe/${subscriptionDetails.msisdn}`, payload)
};

// verify subscription => otp
interface ISubscribeType {
  msisdn: string;
  channel: string;
  otp: string;
  subscriptionId: string;
}

const subscribeByMsisdn = (subscriptionDetails: ISubscribeType) => {
  const payload = {
    channel: subscriptionDetails.channel,
    otp: subscriptionDetails.otp,
    subscriptionId: subscriptionDetails.subscriptionId,
  };

  return httpClient.post(`/subscribe/${subscriptionDetails.msisdn}`, payload);
};

// fetch user subscription
const fetchUserSubscriptions = (subscriptionDetails: ISubscriptionDetails) => {
  return httpClient.get(`/subscribe/${subscriptionDetails.msisdn}`)

  // return httpClient.get(`/subscribe/${subscriptionDetails.msisdn}`)

  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     const productsData = {
  //       data: {
  //         products: [
  //           // {
  //           //   id: 1,
  //           //   title: "Sports-WorldTV",
  //           //   subscriptionId: "P_IMSDI_Sport_490",
  //           // },
  //           {
  //             id: 2,
  //             title: "Celebrity-Fantasy",
  //             subscriptionId: "P_IMSDI_CELEB_491",
  //           }
  //         ],
  //       },
  //     };

  //     resolve(productsData);
  //   }, 1000);
  // });
};

// get content to subscription
interface IFetchSubscription {
  msisdn: string;
  subscriptionId: string;
}
const fetchSubscriptionUrl = (fetchSubscriptionDetails: IFetchSubscription) => {
  return httpClient.get(
    `subscribe/getUrl/msisdn/${fetchSubscriptionDetails.msisdn}/subscriptionId/${fetchSubscriptionDetails.subscriptionId}`
  );

  // return {
  //     message: "https://mtn-zm.sportlocker.com/landing/VuZNs1kesgX1GE6W5LJQZo3FKg8bteCS8Ap2EFtyOSrQl+q0zVAHEQ=="
  // }
};

const subscribeService = {
  subscribeByMsisdn,
  unsubscribeByMsisdn,
  verifySubscription,
  fetchUserSubscriptions,
  fetchSubscriptionUrl,
  subscriptionLogging
};

export default subscribeService;
