import React, { useState, useContext } from "react";
import { ISubscriptionSpec, TJoin } from "../types";
import { UserContext } from "../context/userContext";
import { toast } from "react-toastify";

export const Join = (props: TJoin) => {

  const [contentSpec, setContentSpec] = useState<any>('')
  const {user, setUser} = useContext(UserContext)
  // check the id clicked
  const validateContentId = () => {
    props.setContent(props.contentId);
  };

  const handleSpecChange = (e: any) => {
    setContentSpec(e.target.value)
  }

  // handle DOI process
  // const handleDOI = (item: any) => {
  //   validateContentId();
  //   console.log("handling DOI process", item)

  //   if (contentSpec) {
  //     const CP_PAYLOAD: UrlProps = {
  //       endpoint: 'http://api.vm-vas-sm.mtn.zm:8089/',
  //       CpId: 'IMS DIGITAL',
  //       CpPwd: 'IMSDIGITAL@123',
  //       cpName: 'IMS DIGITAL',
  //       planId: contentSpec,
  //       opId: 1,
  //       transactionId: generateUUID()
  //     }
      
  //     const url = generateCPUrl(CP_PAYLOAD)
  //     console.log(url)
  //     if (url) {
  //       window.open(url, '_self')
  //     }
  //   } else {
  //     toast.error("Error! Please select a subscription plan!", {
  //       position: toast.POSITION.TOP_LEFT,
  //     });
  //   }
  // }

  const handleJoinClick = (item: any) => {
    console.log("i have been clicked")
    validateContentId();

    if (contentSpec) {
      props.setInputMSISDN(true);
      setUser({
        msisdn: '',
        contentSpec: contentSpec,
        subsciptionStatus: 'subscribe'
      })
    } else {
      props.setConfirmation(false)
      toast.error("Error! Please select a subscription plan!", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleAlreadyJoinedClick = (item: any) => {
    validateContentId();
    props.setInputMSISDN(true);
      setUser({
        msisdn: '',
        contentSpec: item?.baseSubscription,
        subsciptionStatus: 'consume'
      })
  }

  return (
    <div className="p-4 flex flex-col items-center gap-2">
      <div className="flex flex-col items-center gap-4">
        <h4 className="text-base font-semibold">Select an option to subscribe to</h4>
        <fieldset>
          {props?.item?.subscriptionSpecs?.map(
            (spec: ISubscriptionSpec, index: number) => (
              <div onChange={handleSpecChange} key={index} className="flex items-center mb-4">

                {/* {props?.item?.id === } */}
                <input
                  id="country-option-1"
                  type="radio"
                  name="countries"
                  onChange={() => {setContentSpec("one")}}
                  value={spec.subscriptionId}
                  className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                />
                <label className="block ml-2 text-sm font-semibold text-black">
                  {spec?.paymentPlan}
                </label>
              </div>
            )
          )}
        </fieldset>
        <button
          type="button"
          onClick={() => handleJoinClick(props?.item)}
          className="text-[#ffcb05] font-black bg-white border border-[#ffcb05] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 hover:text-white hover:bg-[#ffcb05]"
        >
          Click here to join
        </button>
      </div>
      <div className="flex flex-col items-center gap-2">
        <h4 className="text-base font-semibold">Already subscribed</h4>
        <button
          onClick={() => handleAlreadyJoinedClick(props?.item)}
          type="button"
          className="text-[#ffcb05] font-black bg-white border border-[#ffcb05] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 hover:text-white hover:bg-[#ffcb05]"
        >
          Click here to go to {props?.item?.title}
        </button>
      </div>
    </div>
  );
};
