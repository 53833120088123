import React, {createContext, Dispatch, SetStateAction, useState} from "react";

export type Notification = {
    message: string,
    status: string,
    loading: boolean
}

export interface NotificationContextType {
    notification: Notification,
    setNotification: Dispatch<SetStateAction<Notification>>
}

const defaultState = {
    notification: {
        message: "",
        status: "",
        loading: false
    },
    setNotification: (notification: Notification) => {}
} as NotificationContextType

export const NotificationContext = createContext(defaultState)

type NotificationProviderProps = {
    children: React.ReactNode
}

export const NotificationProvider = ({children}:  NotificationProviderProps) => {
    const [notification, setNotification] = useState<Notification>({
        message: "",
        status: "",
        loading: false
    })

    return (
        <NotificationContext.Provider value={{notification, setNotification}}>
            {children}
        </NotificationContext.Provider>
    )
}