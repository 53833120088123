import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../context/userContext";
import { TConfirmationProps } from "../types";
import subscribeService from "../utils/subscribe.service";
import { ContentContext } from "../context/contentContext";
import { NotificationContext } from "../context/notificationContext";
import { generateCPUrl, UrlProps } from "../utils/urls";
import { generateUUID } from "../utils/uuidFunc";

export const Confirmation = (props: TConfirmationProps) => {
  // access the user details from this context
  const { user } = useContext(UserContext);
  const { setContent } = useContext(ContentContext);
  const { setNotification } = useContext(NotificationContext);

  const [pin, setPin] = useState<string>("");

  const handlePinChange = (e: any) => {
    setPin(e.target.value);
  };

  const handlePinSubmit = (e: any) => {
    e.preventDefault();

    // initiate loading
    props.setLoading(true);

    // check whether the subscription id is from base or content plan
    if (user?.subsciptionStatus === "consume") {
      // hit the get url endpoint
      subscribeService
        .fetchUserSubscriptions({
          msisdn: user.msisdn,
        })
        .then((response: any) => {
          // if products is not null'
          props.setLoading(false)
          return response;
        })
        .then((response: any) => {
          props.setLoading(true)
          if (
            response?.data?.status?.code === 0 &&
            response?.data?.products?.length > 0
          ) {
            // fetch the redirect url
            subscribeService
              .fetchSubscriptionUrl({
                msisdn: user?.msisdn,
                subscriptionId: user?.contentSpec,
              })
              .then((res) => {
                props.setLoading(false);
                

                if (res?.status === 200) {
                  toast.success("Url has been fetched successfully", {
                    position: toast.POSITION.TOP_LEFT
                  })
                  setNotification({
                    message: "You are already subscribed.",
                    loading: false,
                    status: "SUCCESS"
                  })
                  setContent({ contentUrl: res?.data?.url });
                  props.setConfirmation(false)
                  props.setEnjoyContent(true);
                } else {
                  toast.error("An error Occured while trying to fetch the Content URL", {
                    position: toast.POSITION.TOP_LEFT
                  })
                }
              });
          } else {
            props.setLoading(false)
            toast.error(`You are not subscribed to any content`, {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          props.setLoading(false)
          toast.error(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "An error occured! Please try again",
            {
              position: toast.POSITION.TOP_LEFT,
            }
          );
        });
    }

    if (user?.subsciptionStatus === "subscribe") {
      subscribeService
        .subscribeByMsisdn({
          msisdn: user.msisdn,
          subscriptionId: user.contentSpec,
          otp: pin,
          channel: "WEB",
        })
        .then((res) => {
          props.setLoading(false);

          console.log(res);

          if (res?.data?.status?.code === 1) {
            // SET ERROR CONTEXT TO THE MESSAGE*
            toast.error(res.data.status.message, {
              position: toast.POSITION.TOP_LEFT,
            });
            props.setConfirmation(false);
          } else if (res?.data?.status?.code === 0) {
            // SUCCESS*
            // SET NOTIFICATIONS TO SUCCESS AND SET THE MESSAGE FROM THE RESPONSE*
            toast.success("Your request has been processed and you will receive an sms shortly.", {
              position: toast.POSITION.TOP_LEFT,
            });
          } else if (res?.data?.status?.code === 101) {
            toast.success("You are already subscribed to the service", {
              position: toast.POSITION.TOP_LEFT,
            });
          }

          return res.data;
        })

        .then((res) => {
          if (res?.status?.code === 101 || res?.status?.code === 0) {
            props.setLoading(true);
            setTimeout(() => {
              console.log("Waiting to hit the get url endpoint");

              subscribeService
                .fetchSubscriptionUrl({
                  msisdn: user.msisdn,

                  subscriptionId: user.contentSpec,
                })
                .then((res) => {
                  props.setLoading(false);

                  console.log("this is the res for get url", res);

                  if (res?.status === 200) {
                    setContent({ contentUrl: res?.data?.url });
                    props.setConfirmation(false);
                    props.setEnjoyContent(true);
                  }

                  // if not return an error message
                  // join screen to try again*
                })
                .catch((err) => {
                  props.setLoading(false);

                  console.log("this is the error from get url", err);

                  if (err) {
                    toast.error(err?.response?.data?.message, {
                      position: toast.POSITION.TOP_LEFT,
                    });

                    props.setConfirmation(false);
                  }

                  // if not return an error message*

                  // join screen to try again*
                });
            }, 15000);
          }
        })

        .catch((err) => {
          props.setLoading(false);

          console.log(err);

          toast.error("An error occured. Please try again!", {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  const handlePinResend = () => {
    console.log("Hit the resend one time pin endpoint");

    // get the msisdn
    // hit the subscribe verify endpoint to resend the sms
    // set loading to false acfter the
  };

  return (
    <div className="p-4 flex flex-col items-center justify-center gap-4">
      <h4 className="text-center font-medium text-sm">
        Enter the one-time pin sent to your mobile number to receive a
        confirmation sms.
      </h4>
      <input
        type="text"
        id="first_name"
        name="pin"
        className="bg-gray-50 w-[80%] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5"
        placeholder="xxxx"
        onChange={handlePinChange}
        required
      ></input>

      <button
        type="button"
        onClick={handlePinSubmit}
        disabled={!pin}
        className={` ${
          !pin
            ? "cursor-not-allowed bg-gray-200"
            : "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 focus:outline-none"
        } text-white w-[80%]  font-medium rounded-lg text-sm px-5 py-2.5 mb-2`}
      >
        Enter
      </button>
      {/* <p className="text-xs">
        Did not receive pin?{" "}
        <button className="text-blue-600" onClick={handlePinResend}>
          resend pin
        </button>
      </p> */}
    </div>
  );
};
