import React, { createContext, Dispatch, SetStateAction, useState } from 'react'

export type Content = {
    contentUrl: string
}

export interface ContentContextType {
    content: Content,
    setContent: Dispatch<SetStateAction<Content>>
}

const defaultState = {
    content: {
        contentUrl: ""
    },
    setContent: (content: Content) => {}
} as ContentContextType

export const ContentContext = createContext(defaultState)

type ContentProviderProps = {
    children: React.ReactNode
}

export const ContentProvider = ({children}: ContentProviderProps) => {

  const [content, setContent] = useState<Content>({
      contentUrl: ""
  })

  return (
    <ContentContext.Provider value={{content, setContent}}>{children}</ContentContext.Provider>
  )
}