import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { IUserContextType } from "../types";

export type User = {
  msisdn: string,
  contentSpec: string,
  subsciptionStatus: string
}

export interface UserContextType {
  user: User,
  setUser: Dispatch<SetStateAction<User>>
}

const defaultState = {
  user: {
    msisdn:  "",
    contentSpec: "",
    subsciptionStatus: ""
  },
  setUser: (user: User) => {}
} as UserContextType

export const UserContext = createContext(defaultState);

type UserProviderProps = {
  children: React.ReactNode
}

export const UserProvider = ({children}: UserProviderProps) => {
    const [user, setUser] = useState<User>({
      msisdn: '',
      contentSpec: '',
      subsciptionStatus: ''
    })

    return (
      <UserContext.Provider value={{user, setUser}}>
        {children}
      </UserContext.Provider>
    );
  };