import axios from "axios";
import { generateUUID } from "./uuidFunc"


const httpClient = axios.create({
  baseURL: "https://dcp.imstechlabs.com/v1"
  // baseURL: process.env.REACT_APP_API_URL
});


// const getAuthToken: any = JSON.parse(localStorage.getItem("token")!)

const authInteceptor  = (config: any) => {
  config.headers["transactionId"] = generateUUID()

  return config
}

// // apply the middleware
httpClient.interceptors.request.use(authInteceptor)

export default httpClient;
