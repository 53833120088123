import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { HomeView } from './pages/HomeView';
import TermsAndConditions from './pages/TermsAndConditions';
import {ToastContainer} from "react-toastify"

import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='/terms' element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default App;
