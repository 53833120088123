import React from "react";

export const Footer = () => {
  return (
    <div className="bg-black text-white w-screen h-[80px] flex items-center">
      <div className="sm:container sm:mx-auto px-2 sm:px-0">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="https://flowbite.com/" className="hover:underline">
            MTN™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
};
