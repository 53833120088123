export const SPORTS_WORLD_TV = `http://api.vm-vas-sm.mtn.zm/API/CGRequest?CpId=IMS DIGITAL&CpPwd=IMSDIGITAL@123&cpName=IMS DIGITAL&reqType=Subscription&reqMode=WAP&transID=10111114&request_locale=en&planId=P_IMSDI_Sport_490&opId=1`;

export const CELEBRITY_CHOICE = `http://api.vm-vas-sm.mtn.zm/API/CGRequest?CpId=IMS DIGITAL&CpPwd=IMSDIGITAL@123&cpName=IMS DIGITAL&reqType=Subscription&reqMode=WAP&transID=10112214&request_locale=en&planId=P_IMSDI_CELEB_493&opId=1`


export interface UrlProps {
    endpoint: string;
    CpId: string;
    CpPwd: string;
    cpName: string;
    planId: string;
    opId: number;
    transactionId: string;
}

export const generateCPUrl = (urlDetails: UrlProps) => {
    
    return `${urlDetails?.endpoint}API/CGRequest?CpId=${urlDetails?.CpId}&CpPwd=${urlDetails?.CpPwd}&cpName=${urlDetails?.cpName}&reqType=Subscription&reqMode=WAP&transID=${urlDetails?.transactionId}&request_locale=en&planId=${urlDetails?.planId}&opId=1`
}

