import React, { useEffect, useState } from "react";

const CelebrityChoice = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const screen_width = window.document.documentElement.clientWidth;
    setScreenWidth(screen_width);
  }, []);

  return (
    <div>
      <h1 style={{ fontWeight: "900", margin: "1rem 0" }}>NOTE</h1>
      <section>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <div>A.</div>
          <div>
            The following Terms and Conditions contain assumptions of risk
            and/or liability by Customers and limit and exclude liabilities,
            obligations and legal responsibilities which MTN(Zambia)
            Limited(“MTN Zambia") and IMS Ventures Digital Proprietary Limited
            (referred to as the “Promoter/s") will have towards Customers and
            other persons. These Terms and Conditions also limit and exclude
            Customers rights and remedies against the Promoter/s and place
            various risks, liabilities, obligations and legal responsibilities
            on Customers.
          </div>
        </div>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <div>B.</div>
          <div>
            Please read these Terms and Conditions carefully. Participation in
            this offer will constitute Customers agreement to comply with these
            Terms and Conditions. If the Customer does not agree with these
            Terms and Conditions, we advise them not participate in this offer.
          </div>
        </div>
      </section>

      <section style={{ margin: "1rem 0" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>
            <span>1.</span> Introduction
          </div>
          <p style={{ margin: "0.5rem 0.5rem" }}>
            <span>a.</span>
            Celebrity Choice is an entertainment game tracking celebrities
            across Music, Movies, TV and Social.
          </p>

          <p style={{ margin: "0.5rem 0.5rem" }}>
            <span>b.</span> Choose one of four celebrities across four different
            categories. Pick from the biggest trending African and Global
            superstars.
          </p>

          <p style={{ margin: "0.5rem 0.5rem" }}>
            <span>c.</span> See your selected celebrities score daily points.
          </p>
        </div>

        <div>
          <div style={{ fontWeight: "bold" }}>
            <span>2.</span> Subscribing
          </div>
          <div style={{ margin: "1rem 0" }}>
            <div style={{ margin: "0.5rem" }}>
              <span>a.</span>
              <span>You may subscribe to the Service via</span>
              <a style={{margin: '0 0.5rem', color: 'blue'}} href="http://web.dcp.imstechlabs.com/" target='_blank' rel="noreferrer">
                http://web.dcp.imstechlabs.com
              </a>{" "}
              or dial *766# or send an SMS to *766#
            </div>
            <div style={{ margin: "0.5rem" }}>
              <span>b.</span>
              Subscribe to the Service from a daily subscription fee of @K3.5
              per day, K10 per week or K25 per month. The subscription is
              automatically renewed subject to you having sufficient airtime.
            </div>
            <div style={{ margin: "0.5rem" }}>
              <span>c.</span>
              Keywords for SMS Subscriptions:
              <div style={{ margin: "1rem" }}>
                <div>
                  <span>i.</span> SWD (Daily)
                </div>
                <div>
                  <span>ii.</span> SWW (Weekly)
                </div>
                <div>
                  <span>iii.</span> SWM (Monthly)
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {screenWidth <= 390 || screenWidth <= 768 ? (
        <section style={{ margin: "1.5rem 0", fontSize: '0.8rem' }}>
          <div style={{display: 'flex'}}>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Service Name</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>SMS Keywords to join</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Subscriptions Price (K)</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Validity</div>
                <div style={{fontWeight: 'bold', padding: '0.2rem'}}>SMS Keywords to cancel</div>
            </div>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>Celebrity Choice Daily</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>CCD</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>2.5</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>1 Day</div>
                <div style={{padding: '0.2rem'}}>STOPDCC</div>
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Service Name</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>SMS Keywords to join</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Subscriptions Price (K)</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Validity</div>
                <div style={{fontWeight: 'bold', padding: '0.2rem'}}>SMS Keywords to cancel</div>
            </div>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>Celebrity Choice Weekly</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>CCD</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>2.5</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>7 Days</div>
                <div style={{padding: '0.2rem'}}>STOPWCC</div>
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Service Name</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>SMS Keywords to join</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Subscriptions Price (K)</div>
                <div style={{fontWeight: 'bold', borderBottom: '1px solid #111', padding: '0.2rem'}}>Validity</div>
                <div style={{fontWeight: 'bold', padding: '0.2rem'}}>SMS Keywords to cancel</div>
            </div>
            <div style={{border: '1px solid #111', width: '50%'}}>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>Celebrity Choice Monthly</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>CCD</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>2.5</div>
                <div style={{borderBottom: '1px solid #111', padding: '0.2rem'}}>30 Days</div>
                <div style={{padding: '0.2rem'}}>STOPMCC</div>
            </div>
          </div>
        </section>
      ) : (
        <section style={{ margin: "1.5rem 0.5rem" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "15vw" }}>
              <div
                style={{
                  border: "1px solid #111",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Service Name {screenWidth}
              </div>
              <div
                style={{
                  border: "1px solid #111",
                  padding: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                Celebrity Choice Daily
              </div>
              <div
                style={{
                  border: "1px solid #111",
                  padding: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                Celebrity Choice Weekly
              </div>
              <div
                style={{
                  border: "1px solid #111",
                  padding: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                Celebrity Choice Monthly
              </div>
            </div>

            <div style={{ width: "15vw" }}>
              <div
                style={{
                  border: "1px solid #111",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                SMS Keywords to Join
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                CCD
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                CCW
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                CCM
              </div>
            </div>

            <div style={{ width: "20vw" }}>
              <div
                style={{
                  border: "1px solid #111",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Subscriptions Price (K)
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                2.5
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                10
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                25
              </div>
            </div>

            <div style={{ width: "15vw" }}>
              <div
                style={{
                  border: "1px solid #111",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Validity
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                1 Day
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                7 Days
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                30 Days
              </div>
            </div>

            <div style={{ width: "15vw" }}>
              <div
                style={{
                  border: "1px solid #111",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                SMS Keywords to Cancel
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                STOPDCC
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                STOPWCC
              </div>
              <div style={{ border: "1px solid #111", padding: "0.5rem" }}>
                STOPMCC
              </div>
            </div>
          </div>
        </section>
      )}

      <section style={{ margin: "1rem 0" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>
            <span>3.</span> Unsubscribing
          </div>
          <div style={{ margin: "1rem 0.5rem" }}>
            <div style={{ margin: "0.5rem 0" }}>
              <span>a.</span> Dial *766# and follow the prompts.
            </div>
            <div style={{ margin: "0.5rem 0" }}>
              <span>b.</span> You will receive an SMS confirming that you have
              been unsubscribed from the Service.
            </div>
          </div>
        </div>

        <div>
          <div style={{ fontWeight: "bold" }}>
            <span>4.</span> General
          </div>
          <div style={{ margin: "1rem 0.5rem" }}>
            <div>
              <div>
                <span>a.</span> The Promoters and MTN Zambia are not responsible
                for:
              </div>
              <div style={{ margin: "1rem 0.5rem" }}>
                <div>
                  <span>i.</span> the service being interrupted and/or failing
                  for any reason or;
                </div>
                <div>
                  <span>ii.</span> any interruption in the network services or;
                </div>
                <div>
                  <span>iii.</span> handset compatibility issues that are
                  experienced on the service.
                </div>
              </div>
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>b.</span> The Promoters and MTN Zambia reserve the right
              from time to time to vary, change, or terminate the Service as may
              be deemed necessary at its sole discretion and on fair notice to
              you. See <a style={{margin: '0 0.5rem', color: 'blue'}} href="www.mtn.zm">www.mtn.zm</a> for rules and updated Terms and Conditions
              from time to time. Any changes to rules and/or Terms and
              Conditions are effective from the date that they are published on
              the website or elsewhere in any media. In the event that you do
              not consent to these Terms and conditions please do not
              participation in the service.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>c.</span> In the event of a dispute, the decision of the
              Promoters and MTN Zambia is final and binding and no
              correspondence will be entered into.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>d.</span> The Promoters and MTN Zambia their respective
              directors, affiliates, members, partners, employees, agents,
              consultants, suppliers, contractors, subsidiaries and sponsors
              assume no liability whatsoever for any direct or indirect loss or
              damage, including but not limited to physical harm or death,
              howsoever arising from or as a result of a person's participation
              through subscribing to the Service and/or these terms and
              conditions. Furthermore, you will be solely responsible for all
              products and items used by you. Accordingly, the Promoters take no
              responsibility for any loss or damage, or any MTN airtime
              associated with the SIM card and/or cellphone number. In the event
              of theft or loss of your cellphone, airtime and/or SIM card, such
              items must be replaced at your own cost, and you are obliged to
              report such loss or theft to the Zambian Police Services
              immediately. All persons using the Service hereby expressly
              indemnify the Promoter/s in this regard and shall hold MTN Zambia
              and the Promoters harmless from all and any claims arising from or
              as a result of the above reasons.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>e.</span> Any subscription completed on the customer’s
              number or SIM card shall be deemed to have been initiated by the
              Customer and neither MTN Zambia nor the Promoter shall be liable
              should it later be shown that the subscription was initiated by
              another person without the subscriber’s consent.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>f.</span> By subscribing to the service, the customer
              consents to MTN Zambia and the Promoter using the customer’s
              information for purposes of the promotion and the customer agrees
              to hold MTN Zambia and the Promoter harmless for use of their
              personal information for the promotion.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>g.</span> MTN reserves the right from time to time to
              terminate, vary or change the Service as may be deemed necessary
              at its sole discretion. See <a style={{margin: '0 0.2rem', color: 'blue'}} target="_blank" rel="noreferrer" href="www.mtn.zm">www.mtn.zm</a>  for updated Terms and
              Conditions from time to time. Any changes to rules and/or Terms
              and Conditions are effective from the date that they are published
              on the website or elsewhere in any media. In the event that you do
              not consent to the Terms and Conditions please do not participate
              in the Promotion or subscribe to the Service.
            </div>

            <div style={{ margin: "1rem 0" }}>
              <span>h.</span> MTN shall be entitled to terminate your
              subscription to the Service, if you are involved in any fraudulent
              activity or are suspected of fraudulent activity.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CelebrityChoice;
